<template>
    <div class="container-fluid">
      <div class="row col-14">
        <div class="col-2 ">
          <Icon
              name="buy-empty"
              class=" img-auto mt-4"
          />   
      <div class="text-product">
      <span >Produto</span>
    </div>
  </div>
 
   <div class="col-3">
    <Icon
              name="feature-gray"
              class=" img-auto mt-5"
          />   
  </div>
 

  <div class="col-2"> 
    <Icon
              name="login-gray"
              class=" img-auto mt-4"
          />   
      <div class="text-personal">
      <span >Pessoal</span>
    </div>
  </div>

  <div class="col-3">
    <Icon
              name="feature-gray"
              class=" img-auto mt-5"
          />   
  </div>
    
 

  <div class="col-2"> 
    <Icon
              name="lock-gray"
              class=" img-auto mt-4"
          />   
      <div class="text-conclude ">
      <span >Concluir</span>
    </div>
  </div>
    </div>
    </div>
</template>

<script>
    export default {
        name: "PersonalStep"
    }
</script>

<style scoped>
.text-product {
font-size: 11px;
color: #165DBA;
font-family: Verdana, Geneva, Tahoma, sans-serif;
margin-left: -5px;



}
.text-personal{
font-size: 11px;
color: #bdc1c5;
font-family: Verdana, Geneva, Tahoma, sans-serif;
margin-left: -5px;



}
.text-payment {
font-size: 11px;
color: #bdc1c5;
font-family: Verdana, Geneva, Tahoma, sans-serif;
margin-top: 5px;
margin-left: -12px;





}
.text-conclude {
font-size: 11px;
color: #bdc1c5;
font-family: Verdana, Geneva, Tahoma, sans-serif;
margin-top: 5px;
margin-left: -10px;


}

@media screen and (max-width: 768px) {
  .size-icons{

    width: 18px;
  
}
  
}
</style>