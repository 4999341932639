<template>
  <div class="col-12">
    <div class="mt-3">
      <span class="text-gray">Data 1</span>
      <DatePicker
        @input="selectDateOne"
        :allowDates="true"
        :disableWeekends="true"
        class="col-12"
      />      
      <div>
        <div class="row">
          <div class="col-12">
            <div
              class="form-check"
              v-for="(
                period, index
              ) in getProductInstallationSchedulePeriodComputed"
              :key="index">
              <input
                class="form-check-input"
                type="radio"
                :name="'flexRadioDefault1' + index"
                :id="'flexRadioDefault1' + index"
                :value="period.id"
                v-model="installationSchedulePeriodOne"
              />
              <label :for="'flexRadioDefault1' + index">
                {{ period.description }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <span class="text-gray">Data 2</span>
      <DatePicker        
        @input="selectDateTwo"
        :allowDates="true"
        :disableWeekends="true"
        class="col-12"/>
    </div>
    <div class="row">
      <div class="col-12">
        <div
          class="form-check"
          v-for="(
            period, index
          ) in getProductInstallationSchedulePeriodComputed"
          :key="index">
          <input
            class="form-check-input"
            type="radio"
            :name="'flexRadioDefault2' + index"
            :id="'flexRadioDefault2' + index"
            :value="period.id"
            v-model="installationSchedulePeriodTwo"
          />
          <label :for="'flexRadioDefault2' + index">
            {{ period.description }}
          </label>
        </div>
      </div>
    </div>
    <div class="row mt-3 mb-5">
      <span class="text-form row ms-0">Contato para instalação</span>
      <div class="row mt-3">
        <div class="col-6">
          <span>Nome</span>
          <input
            class="rounded border form-control"
            placeholder="Nome"
            v-model="contacts.name"
            @input="validateFormCustomer('name')"
          />
            <small class="form-text text-danger">
            {{ res.getErrors("name")[0] }}
          </small>
        </div>
        <div class="col-6">
          <span>Celular</span>
          <input
            class="rounded border form-control"
            placeholder="(11)99999-9999"
            v-model="contacts.phone"
            v-mask="'(##) #####-####'"
            @input="validateFormCustomer('phone')"
          />
          <small class="form-text text-danger">{{ res.getErrors("phone")[0] }}</small>
        </div>
        <small class="form-text text-danger mt-4">
          Para a instalação é necessario um responsável maior de 18 anos.
        </small>
      </div>
    </div>
    <div class="col-12">
      <button
        tag="Continuar"
        pill
        variant="danger"
        type="submit"
        value="Submit"
        class="btn btn-primary text-center col-12 col-sm-4 col-md-4 col-lg-5 loading-button mb-3"
        @click="nextStep">
        <span v-if="!processing">Continuar</span>
        <span v-else>
          <div class="text-center">
            <strong class="text-sm">Aguarde o processamento......</strong>
            <div
              class="spinner-border ml-auto"
              role="status"
              aria-hidden="true"
              style="width: 16px; height: 16px"
            ></div>
          </div>
        </span>
      </button>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import suite from "@/services/validations/customerValidations.js";
import { mapActions, mapGetters } from "vuex";
import DatePicker from "@/components/Calendar/DataPicker.vue";
export default {
  name:"Schedule",
  components: {
    DatePicker
  },
  data() {
    return {
      disablePastDates: true,
      installationScheduleDateOne: null,
      installationScheduleDateTwo: null,
      installationSchedulePeriodOne: null,
      installationSchedulePeriodTwo: null,
      contacts: {
        name: null,
        phone: null,
      },
      processing: false,
      res: suite.get(),
      fieldsData: {
        hasError: () => {
          return true; 
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getProductInstallationSchedulePeriodComputed:
        "saleFlow/schedule/getProductInstallationSchedulePeriod",
      getSaleOfService: "saleFlow/getSaleOfService",
    }),
    isValid() {
      return this.contacts.name && this.contacts.phone;
    },
    disabledDatesComputed() {
      let disabledDates = {};
      if (this.disablePastDates) {
        const today = moment().startOf("day");
        disabledDates.from = today.toDate();
      }
      return disabledDates;
    }    
  },
  methods: {
    ...mapActions({
      getInstallation: "saleFlow/schedule/setProductInstallationSchedulePeriod",
      getProductInstallationSchedulePeriodAction:"saleFlow/schedule/setInstallationSchedule",
      installationSchedule: "saleFlow/installationSchedule",
      getScheduleInstallation: "schedule/getScheduleInstallation",
    }),
    validateFormCustomer(field) {
      this.res = suite(
        {
          name: this.contacts.name,
          phone: this.contacts.phone
        },
        field
      );
    },
validateContacts() {
      const fields =
        this.contacts.name &&
        this.contacts.phone
      return fields;
},

    selectDateOne (formattedDate) {
      this.installationScheduleDateOne = formattedDate
    },
    selectDateTwo (formattedDate) {
      this.installationScheduleDateTwo = formattedDate
    },
    async nextStep() {
      try {
        if (!this.validateContacts()) {
          this.$swal("Por favor, preencha todos os campos.");
          return;
        }
        const phoneTreatByApi = this.contacts.phone.replace(/\D/g, '');
        const formattedPhone = `+55${phoneTreatByApi}`;


        const formattedDateOne = moment(this.installationScheduleDateOne, "DD/MM/YYYY").format("YYYY-MM-DD");
        const formattedDateTwo = moment(this.installationScheduleDateTwo, "DD/MM/YYYY").format("YYYY-MM-DD");
        
        await this.installationSchedule({
          contacts: [
            {
              name: this.contacts.name,
              phone: formattedPhone,
            },
          ],
          schedules: [
            {
          date: formattedDateOne,
          productInstallationSchedulePeriodId: this.installationSchedulePeriodOne,
            },
            {
          date: formattedDateTwo,
          productInstallationSchedulePeriodId: this.installationSchedulePeriodTwo,
            },
          ],
          id: this.getSaleOfService?.id,
        });
        this.$swal("Dados enviados com sucesso", "", "success");
        this.goNext();
      } catch (error) {
        this.$swal("Ops, algo deu errado. Dados não enviados.");
      }
    },
    goNext() {
      this.$router.push({ name: "CustomerStep" });
    }
  }
};
</script>
<style scoped>
.text-form {
  font-size: 14px;
  color: rgb(88, 87, 87);
  font-weight: bold;
  align-items: center;
  justify-self: center;
}
.loading-button i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: spinner 1s infinite linear;
}
@keyframes spinner {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
