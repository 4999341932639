<template>
  <div>
    <CardCart/>
    <CheckoutStep/>
    <div><span class="text-center text-confirm">Quase lá!  Confira os dados abaixo:</span></div>
    <ListProduct/>
    <ListDataClient/>
    <ReviewInstallationData v-if="getShowClaroFieldsComputed"/>
    <div class="form-customer row">
      <span class="text-form"></span>
      <div class="container-fluid row"></div>
    </div>
    <div class="row mt-3">
      <PaymentForm ref="paymentFormRef"
        :checkout-payment-items="getCheckoutPaymentItemsComputed"
        :checkout-payment-data-items="getCheckoutPaymentDataItemsComputed"
        :set-checkout-payment-data-item="setCheckoutPaymentDataItem"
        :next-step="nextStep"
        :acceptedBrands="acceptedBrands"
      />
    </div>

    <Modal :close="() => showModalCancel = false" :modal-active="showModalCancel">
      <template v-slot:default="{close}">
        <div class="p-5 d-flex justify-content-center flex-column gap-4 align-items-center">
          <Icon name="warning" class="warning-icon" />

          <p class="text-center">Não foi possível finalizar a sua compra, por favor revise os dados de pagamento e tente novamente. Persistindo o erro, contate o órgão emissor do seu cartão.</p>

          <b-button type="button" class="btn w-100 btn-danger rounded" @click="close">Fechar</b-button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import PaymentForm from "@/components/Form/PaymentForm.vue";
import CardCart from "@/components/Cards/CardPernambucanas.vue";
import CheckoutStep from "@/components/Steps/PaymentStep.vue";
import ListProduct from "@/components/Sale/ListProduct.vue";
import ListDataClient from "@/components/Sale/ListDataClient.vue";
import ReviewInstallationData from "@/components/Schedule/ReviewInstallationData.vue";
import { mapActions, mapGetters } from "vuex";
import StatusConstants from "@/constants/status/status.constants.js";
import GoogleService from "@/services/tracking/google.service";
import Partners from "@/constants/partner/partner.constants";
import Modal from "@/components/Modal/WarningModal";

export default {

  name: "Payment",
  components: {
    PaymentForm,
    CardCart,
    CheckoutStep,
    ListProduct,
    ListDataClient,
    ReviewInstallationData,
    Modal
  },
  data() {
    return {
      showModalCancel: false,
      acceptedBrands: []
    }
  },
  computed: {
    ...mapGetters({
      getCheckoutPaymentItemsComputed: 'saleFlow/checkout/getCheckoutPaymentItems',
      getCheckoutPaymentDataItemsComputed: 'saleFlow/checkout/getCheckoutPaymentDataItems',
      isOrderAlreadyGeneratedComputed: 'saleFlow/isOrderAlreadyGenerated',
      getShowClaroFieldsComputed: "address/getShowClaroFields",
      getOrders: "saleFlow/getOrders",
      getPartnerComputed: "getPartner",
    })
  },
  methods: {
    ...mapActions({
      setCheckoutPaymentDataItemAction: 'saleFlow/checkout/setCheckoutPaymentDataItem',
      checkoutOrderAction: 'saleFlow/checkoutOrder',
      checkoutActivateServiceAction: 'saleFlow/checkoutActivateService'
    }),
    setCheckoutPaymentDataItem(checkoutPaymentItem, payload) {
      this.setCheckoutPaymentDataItemAction({
        checkoutPaymentItem,
        checkoutPaymentData: payload
      })
    },
    //@toDo: Review this function when the aplication change to mult-services
    checkStatus(response) {
      const lastCreatedSalesOrder = this.getOrders.at(-1);

      if (lastCreatedSalesOrder?.detailOrder?.status?.current === StatusConstants.CANCELED) {
        this.showModalCancel = true;
        this.$refs.paymentFormRef.checkOrderStatus();
      }

      if (lastCreatedSalesOrder?.detailOrder?.status?.current === StatusConstants.APPROVED) {
        this.gtmSendSaleCompletedEvent(response.details)
        this.$router.push({ name: 'OrderStep'})
      }
    },
    closeModal() {
      this.showModalCancel = false
    },
    nextStep () {
      this.sendCheckoutOrder()
        .then(() => this.checkoutActivateServiceAction())
        .then(response => this.checkStatus(response))
    },
    sendCheckoutOrder () {
      return this.checkoutOrderAction()
    },

    gtmSendSaleCompletedEvent(details) {
      const eventPayload = {
        event: 'sale_created',
        id: details.saleOfService.id,
      };

      GoogleService.gtagPush(eventPayload);
    },
  },

  mounted()
  {
    if (this.getPartnerComputed === Partners.MASTERCARD) {
      this.acceptedBrands.push('mastercard');
    }
  }
}
</script>

<style scoped>
.form-customer {
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10px;
  color: darkgray;
  font-size: 12px;
}
.form-adress {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  color: darkgray;
  font-size: 12px;
}
.text-form {
  font-size: 14px;
  color: rgb(88, 87, 87);
  align-items: center;
  justify-self: center;
  font-weight: bold;
}
.button-next {
  margin-left: 10px;
  margin-right: 10px;
}
.checkbox-terms {
  font-size: 11px;
  color: rgb(66, 65, 65);
}
.container-checkbox {
  margin-bottom: 100px;
}
.card-car {
  margin-top: 30%;
  height: 20%;
}
.text-car {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 20px;
  color: white;
  background-color: rgb(99, 96, 96);
}
.text-confirm {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #29333D;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 35px;
}
</style>
