<template>
  <div class="mt-3">
    <div
      class="container"
      v-for="cartItem in getCartItems"
      :key="cartItem.item.product.id">
      <b-card class="shadow border-1" style="background-color: #f3f3f3">
        <img
          src="@/assets/img/companies/McAfee/McAfeeCommon/iconBuyEmpty.svg"
          class="img-auto"
          alt="Responsive image"
        />
        <span>Pedido</span>
        <b-card-text class="text m-2">ID do Serviço</b-card-text>
        <b-card-text class="m-1 col-12" style="font-size: 11px">
          {{ getCartProductId }}
        </b-card-text>
        <hr />
        <div v-if="hasAvailableProductTypeClaroComputed">
          <a href="tel:*728">
            <span class="text-voice">Lembre-se para ativar seu plano clique aqui</span>
          </a>
        </div>
        <b-card-title class="text-card-product-list mt-3 m-1">
          {{ cartItem.item.product.name }}
        </b-card-title>
        <b-card-text class="large m-1">
          {{ cartItem.item.product.description }} <br />
        </b-card-text>
        <br />
        <div class="price-mcafee">
          {{ cartItem.item.promotion?.description }} ____
          {{ currencyBRFormat(cartItem.price) }}
        </div>
        <div v-if="checkHasAdditionalPointsByCartItem(cartItem)">
          <div
            v-for="cartItem in getCartAdditionalAmountValue"
            :key="cartItem.item.productResidentialPointAdditionalType?.id">
            <div class="price-mcafee">
              {{ cartItem.item.productResidentialPointAdditionalAmount }} pontos adicionais
              {{ cartItem.item.productResidentialPointAdditionalType?.description }} ____ {{ currencyBRFormat(cartItem.itemPoint) }}
            </div>
          </div>
        </div>
        <br />
        <b-card-text class="small m-1"></b-card-text>
        <b-card-text class="text-card-product-list m-1">
          {{ currencyBRFormat(totalPrice) }}/ano
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import MoneyService from "@/services/numbers/money";

export default {
  name: "OrderProduct",
  data() {
    return {
      totalPrice: 0,
    };
    },
  computed: {
    ...mapGetters({
      getCartCheckoutDetailsComputed: "saleFlow/cart/getCheckoutDetails",
      getCheckoutProductDetailsComputed: "saleFlow/cart/getCheckoutProductsItems",
      hasAvailableProductTypeClaroComputed:"saleFlow/product/hasAvailableProductTypeClaro",
      getShowClaroFieldsComputed: "address/getShowClaroFields",
      getCartDetailsComputed: "cart/getCartDetails",
      getCartTotalPriceComputed: "cart/getCartTotalPrice",
      getSelectedAdditionalPoints: "saleFlow/cart/getSelectedAdditionalPoints",

    }),
    getCartItems() {
      return this.getCartCheckoutDetailsComputed?.items || [];
    },
    getCartTotalPrice() {
      return this.getCartCheckoutDetailsComputed?.total || 0;
    },
    getCartProductId() {
      return this.getCheckoutProductDetailsComputed?.[0].id || [];
    },
    currencyBRFormat() {
      return (value) => MoneyService.currencyBRFormat(value || 0);
    },
    getCartAdditionalAmountValue() {
      return this.getTotalPriceComputed?.items || 0;
    },
    getCartTotalPricePoints() {
      return this.getSavedTotalPrice || 0;
    },

  },

  methods: {
  
    ...mapActions({
      clearCartAction: "cart/clearCart",
    }),
    checkHasAdditionalPointsByCartItem(cartItem) {
      return (cartItem?.item?.checkoutProduct?.productResidentialPointAdditionalAmount > 0
      )
    },
    clearCart() {
      this.clearCartAction()
    },
    clear() {
      this.getTotal()
      setTimeout(() => {
      this.clearCartAction();
      }, 3000); 
      },
    getTotal() {
      const totalPrice = this.getCartTotalPriceComputed;
      this.totalPrice = totalPrice;
        return this.totalPrice;
},
  },
  mounted() {
     this.clear();
  }, 
};
</script>
<style scoped>
.text-card-product-list {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #29333d;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.edit-button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 24px;
  gap: 10px;
  color: #165dba;
  position: absolute;
  left: 65.88%;
  right: 2%;
  top: 3%;
  bottom: -10;
  background: #ffffff;
  border: 2px solid #d4e0f7;
  border-radius: 8px;
}
.text--button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  color: #165dba;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.text-id {
  display: flex;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  align-items: center;
  letter-spacing: 0.004em;
  flex-wrap: wrap;
  color: #050505;
}
.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.004em;
  color: #5c6670;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.text-voice {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.004em;
  color: crimson;
  background-color: pink;
}
</style>