<template>
    <div class="row">
        <div class="card-car px-4 d-flex align-items-center gap-3">
            <div class="elipse rounded-circle bg-white">
                <img
                    src="@/assets/img/companies/McAfee/McAfeeCommon/Single-Check.svg"
                    alt="carrinho de compras"
                    class="img-auto mt-1"
                />
            </div>

            <span class="text-car text-white fw-semibold">Parabéns, seu pedido foi concluído!</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "CardCongratulations",
};
</script>

<style scoped>
img {
    width: 80px;
}

.elipse {
    width: 80px;
    height: 80px;
}

.text-car {
    font-family: "Roboto";
    font-size: 26px;
    line-height: 30px;
}

.card-car {
    height: 150px;
    background: #1D3C85;
}
</style>
