<template>
  <CardCartComponent />
  <ScheduleStep />
  <div 
    class="row mt-3"
    v-for="cartItem in getCartItems"
    :key="cartItem.item.product.id">
    <div class="col-6">
      <h6 class="text-mcafee" style="color: black">
        {{ cartItem.item.product.name }}
      </h6>
    </div>
    <div class="col-6">
      <b-button
        @click="removeCart(cartItem.item.product)"
        type="button"
        class="rounded edit-button p-1 col-9"
        style="color: blue">
        <img src="@/assets/icons/vector.svg" alt="" />
        <span class="ms-1 text-">Remover</span>
      </b-button>
    </div>
  </div>
  <div class="form-customer">
    <span class="fs-5 ms-0 text-color-black">Dados de instalação</span>
    <span class="text-form row mt-2 ms-0" >Escolha até duas opções de data e período de instalação:</span>
    <ScheduleComponent />
  </div>
</template>
<script>
import CardCartComponent from "@/components/Cards/CardPernambucanas.vue";
import ScheduleStep from "@/components/Steps/ProductStep.vue";
import ScheduleComponent from "@/components/Schedule/Schedule.vue";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "Schedule",
  data() {
    return {};
  },
  components: {
    CardCartComponent,
    ScheduleStep,
    ScheduleComponent,
  },
  computed: {
    ...mapGetters({
      getCartDetailsComputed: "cart/getCartDetails",
    }),
    ...mapState({
      isShowingCart: (state) => state.cart.isShowingCart,
    }),
    getCartItems() {
      return this.getCartDetailsComputed?.items || [];
    },
    getCartTotalPrice() {
      return this.getCartDetailsComputed?.total || 0;
    }
  },
  methods: {
    ...mapActions({
      closeCartAction: "cart/closeCart",
      removeCartItemAction: "cart/removeProductCart"
    }),
    closeCart() {
      this.closeCartAction();
    },
    removeCart(product) {
      this.removeCartItemAction(product);
      return this.$swal(
        "Olá, seu carrinho está vazio. você pode voltar e escolher um produto. ",
        " ",
        this.$router.push({ path: "/clarotv" })
      );
    },
    getStepCart() {
      if (
        this.getCartItems.length == [] ||
        this.getCartItems.length == 0 ||
        this.getCartItems.length == null
      ) {
        return this.$swal(
          "Por favor, adicione um produto ao carrinho para continuar.",
          " ",
          "error"
        );
      }
    }
  }
};
</script>
<style scoped>
.form-customer {
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10px;
  color: black;
  font-size: 12px;
}
.form-adress {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  color: darkgray;
  font-size: 12px;
}
.text-form {
  font-size: 14px;
  color: rgb(88, 87, 87);
  font-weight: bold;
  align-items: center;
  justify-self: center;
}
.button-next {
  margin-left: 10px;
  margin-right: 10px;
}
.checkbox-terms {
  margin-left: 5px;
  font-size: 11px;
  color: rgb(66, 65, 65);
}
.container-checkbox {
  margin-bottom: 100px;
}
.text-mcafee {
  margin-left: 4.5%;
  margin-top: 5%;
  font-size: 12px;
}
.edit-button {
  background: #ffff;
  margin-left: 20%;
  margin-top: 5%;
  font-size: 12px;
}
</style>