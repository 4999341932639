<template>
   <CardCongratulations/>
   <div class="container " style="margin-bottom: 5%;">
     <div class="form-customer">
       <span class="text-verify mt-2"> Verifique o seu e-mail para conferir todas as
          informações sobre sua compra.
       </span>
     </div>
     <br>
     <div class="container">
       <span class="text-remember"> Lembre-se de verificar também as pastas de
         spam ou lixo eletrônico em seu provedor de e-mail.
       </span>
     </div>
  </div>
   <OrderProduct class="mt-4"/>
  <div class="container mt-4 container-checkbox">  
</div>
</template>

<script>
import CardCongratulations from "@/components/Cards/CardCongratulations.vue";
import OrderProduct from "@/components/Sale/OrderProduct.vue";
import { mapGetters } from "vuex";

export default {
  name: "Order",
  data() {
    return { };
  },
  components: {
    CardCongratulations,
    OrderProduct,
  },
  mounted() {
   
  },
  computed: {
    ...mapGetters({
      getCurrentAddressComputed: "address/getCurrentAddress",
      hasAvailableProductTypeClaroComputed: 'saleFlow/product/hasAvailableProductTypeClaro',
    }),
  
  },
  methods: {
      
    }
  }
</script>
<style lang="scss" scoped>
.form-customer{
margin-top: 30px;
margin-left: 10px;
margin-right: 10px;
color: darkgray;
font-size: 12px;
}
.form-adress{
margin-top: 20px;
margin-left: 10px;
margin-right: 10px;
color: darkgray;
font-size: 12px;
}
.text-verify{
/* Mobile/H3 Medium */
font-family: 'Roboto';
font-style: normal;
font-size: 14px;
color: rgb(88, 87, 87);
align-items: center;
justify-self: center;
font-weight: bold;
/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;
}
.text-remember{
/* Mobile/H4 Regular */
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
/* Text/Gray/100% */
color: #29333D;
/* Inside auto layout */
flex: none;
order: 1;
flex-grow: 0;
}
.button-next{
margin-left: 10px;
margin-right: 10px;
}
.checkbox-terms {
font-size: 11px;
color: rgb(66, 65, 65);
}
.container-checkbox { 
margin-bottom: 100px;
}
.card-car {
    margin-top: 30%;
    height: 20%;
}
.text-car {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 20px;
    color: white;
    background-color: rgb(99, 96, 96);
}
.address-bar {
  background-color: #e3262e;
  color: white;
  font-size: 11px;
  font-weight: 700;
}
</style>