<template>
  <div>
    <HomeContainer>
      <template v-slot:header>
        <SystemHeader/>
      </template>
      <template v-slot:main>
      <div class="container align-items-center">
      <router-view/>
      </div>
      </template>
      <template v-slot:footer>
        <SystemFooter/>
      </template>      
    </HomeContainer>
  </div>
</template>
<script>
import SystemHeader from "@/components/Header/Header.vue";
import SystemFooter from "@/components/Footer/Footer.vue";
import {mapActions} from "vuex";
import HomeContainer from "@/components/Container/HomeContainer.vue";

export default {
  name: "SaleFlow",
  components: {
    SystemHeader,
    SystemFooter,
    HomeContainer
  },
  mounted () {
    this.setCurrentPointOfSale()
  },
  methods: {
    ...mapActions({
      setCurrentPointOfSale: 'auth/user/pointOfSale/setCurrent'
    }),
  }
}
</script>
