<template>
  <div class="mt-3">
    <div class="container">
      <b-card class="shadow border-1" style="background-color: #f3f3f3">
        <img
          src="@/assets/img/companies/McAfee/McAfeeCommon/iconLogin.svg"
          class="img-auto"
          alt="Responsive image"
        />
        <span> Dados do cliente </span>
        <div class="col-12 row">
          <b-card-text class="text-card-data-client mt-3 col-12">
            Nome completo <br />
            <strong class="data-client">{{ customerComputed.name }}</strong>
          </b-card-text>
          <b-card-text
            class="text-card-data-client mt-1 col-12"
            v-if="getShowClaroFieldsComputed">
            Nome completo da mãe <br />
            <strong class="data-client">{{customerComputed.motherName }}</strong>
          </b-card-text>
          <b-card-text class="text-card-data-client col-12">
            CPF<br />
            <strong class="data-client" >{{formattedCPF}} </strong>
          </b-card-text>
          <b-card-text class="text-card-data-client col-12">
            Telefone<br />
            <strong class="data-client">{{formattedPhone}} </strong>
          </b-card-text>
          <b-card-text class="text-card-data-client col-12">
            E-mail<br />
            <strong class="data-client"> {{ customerComputed.email }}</strong>
          </b-card-text>
          <div v-if="getShowClaroFieldsComputed" class="col-12 row">
            <b-card-text class="text-card-data-client col-12">
              CEP <br />
              <strong  class="data-client">{{formattedCEP}}</strong>
            </b-card-text>
            <b-card-text class="text-card-data-client col-12">
              Endereço<br />
              <strong class="data-client">{{
                addressDetails.fullAddress
              }}</strong>
            </b-card-text>
            <b-card-text class="text-card-data-client col-5">
              Complemento<br />
              <strong class="data-client">{{ addressDetails.complement }}</strong>
            </b-card-text>
            <b-card-text class="text-card-data-client col-4">
              Cidade<br />
              <strong class="data-client">{{ addressDetails.cityName }}</strong>
            </b-card-text>
            <b-card-text class="text-card-data-client col-2">
              UF<br />
              <strong class="data-client">{{ addressDetails.stateName  }}</strong>
            </b-card-text>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ListDataClient",
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      customerAddressComputed: "customer/getCustomerAddress",
      getAddresses: "address/getCurrentAddress",
      customerComputed: "customer/getCustomer",
      getShowClaroFieldsComputed: "address/getShowClaroFields",
    }),
    formattedCPF() {
      if (this.customerComputed && this.customerComputed.cpf) {
        return this.customerComputed.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/,'$1.$2.$3-$4');
      }
      return "";  
    },
    formattedPhone() {
  if (this.customerComputed && this.customerComputed.phone1) {
    const phoneNumber = this.customerComputed.phone1.replace(/\D/g, '');
    const maskedPhoneNumber = `(${phoneNumber.slice(2, 4)}) ${phoneNumber.slice(4, 9)}-${phoneNumber.slice(9)}`;
    return maskedPhoneNumber;
  }  
  return "";
},
    
    formattedCEP(){
      if (this.getAddresses && this.getAddresses.postalCode) {
        return this.getAddresses.postalCode.replace(/^(\d{5})(\d{3})$/, '$1-$2');
      }
      return "";
    },
    addressDetails() {
    const streetName = this.getAddresses?.streetName;
    const number = this.customerAddressComputed?.number;
    const neighborhoodName = this.getAddresses?.neighborhoodName;
    const complement = this.customerAddressComputed?.complement;
    const cityName = this.getAddresses?.cityName;
    const stateName = this.getAddresses?.stateName;

    return {
      fullAddress: `${streetName}, ${number} - ${neighborhoodName}`,
      complement: complement,
      cityName: cityName,
      stateName: stateName,
    };
  },
  }
};
</script>
<style scoped>
.text-card-data-client {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.004em;
  color: #5c6670;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.edit-button {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 10px;
  color: #165dba;
  position: absolute;
  left: 65.88%;
  right: 2%;
  top: 3%;
  bottom: -10;
  background: #ffffff;
  border: 2px solid #d4e0f7;
  border-radius: 8px;
}
.text-button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  color: #165dba;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.data-client {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #29333d;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.text-edit-client {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.0025em;
  color: #29333d;
}
.title-modal {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #e71a3b;
}
.button-modal {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.text-edit {
  font-family: "Roboto";
  font-style: bold;
}
</style>
