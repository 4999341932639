import { create, test, enforce, only, inside } from "vest";

const MASTERCARD_PATTERN_REGEX = /^5[1-5][0-9]{14}$/;

export default create((data) => {
  only(data);

  test("cardNumber", "Número do cartão é obrigatório.", () => {
    enforce(data.cardNumber).isNotEmpty();
  });

  test("cardNumber", "Número do cartão deve ter 16 dígitos", () => {
    enforce(data.cardNumber).longerThanOrEquals(16);
  });

  test(
    "onlyBrands",
    data?.onlyBrands.length
      ? "Bandeiras aceitas: ".concat(data?.onlyBrands?.join(", "))
      : "Bandeira inválida",
    () => {
      if (data?.onlyBrands?.length) {
        enforce(data.cardBrand).inside(data.onlyBrands);
        return;
      }

      enforce(data.cardBrand).isString();
    }
  );

  test("cardHolder", "Nome do titular do cartão é obrigatório", () => {
    enforce(data.cardHolder).isNotEmpty();
  });

  test(
    "cardHolder",
    "Nome do titular do cartão deve conter apenas letras",
    () => {
      enforce(data.cardHolder).matches(/^[A-Za-z ]+$/);
    }
  );

  test("cardHolder", "Digite o nome completo do titular ", () => {
    enforce(data.cardHolder).matches(/^(\S+)\s+(\S.*)$/);
  });

  test("cardExpirationDate", "Data de validade do cartão é obrigatória", () => {
    enforce(data.cardExpirationDate).isNotEmpty();
  });

  test(
  "cardExpirationDate",
  "Data de validade do cartão deve ser maior que a data atual",
  () => {
    const today = new Date();
    let [month, year] = data.cardExpirationDate.split('/');
    year = parseInt(year) + 2000;

    const adjustedExpirationDate = new Date(year, parseInt(month, 10) - 1);

      adjustedExpirationDate.setMonth(adjustedExpirationDate.getMonth() + 1);
      adjustedExpirationDate.setDate(0);

      today.setDate(1);

      if (adjustedExpirationDate <= today) {
        throw new Error("A data de validade do cartão deve ser maior que a data atual");
      }
    }
  );

  test("cardCVV", "CVV é obrigatório", () => {
    enforce(data.cardCVV).isNotEmpty();
  });

  test("cardCVV", "CVV deve ter 3 dígitos", () => {
    enforce(data.cardCVV).lengthEquals(3);
  });

  test("numberInstallments", "Número de parcelas é obrigatório", () => {
    enforce(data.numberInstallments).isNotEmpty();
  });

  test("numberInstallments", "Número de parcelas deve ser maior que 0", () => {
    enforce(data.numberInstallments).longerThanOrEquals(1);
  });
});
