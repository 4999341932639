<template>
  <Modal :modal-active="showModal" @closeModal="closeModal">
    <template v-slot:default="{}">
      <div class="text-center mt-3 bg-modal">
        <div class="col-12 center-div">
          <div class="d-flex align-items-center col-6">
            <img
              src="@/assets/icons/warning-circle-.svg"
              class="img-auto warning-icon col-6 mx-auto"
              alt="Responsive image"
              style="width: 50px"
            />
            <h4 class="container text-center text1 m-0">AVISO!</h4>
          </div>
        </div>
        <div v-if="!showCountdown">
          <div class="mt-5 text-center text2">
            O processo de validação dos documentos pode levar até 3 minutos.
            Caso você não tenha realizado o envio da documentação
            você poderá realizá-lo no botão abaixo.
            <br/>
            <br/>
          </div>
          <button
            v-if="showVerifyButtonOne"
            type="button"
            class="btn mb-4 button-verify col-7 mx-1 btn btn-outline-danger"
            @click="startVerification">
            Fazer Verificação
          </button>
          <button
            v-if="showVerifyButtonTwo"
            type="button"
            class="btn mb-5 button-verify col-7 mx-1 btn btn-outline-danger"
            @click="authenticationPerformed">
            Ja Realizei
          </button>
        </div>
        <button v-if="(showCountdown || ok) && showOkButton && !showMessageWithCountdown"
          type="button"
          class="btn mb-5 button-verify col-7 mx-1 btn btn-outline-danger"
          @click="completedAuthentication">
          Ja Realizei
        </button>

        <!-- Message with countdown 59 seconds -->
        <div v-if="showMessageWithCountdownOne" class="mt-4">
          <p class="text-color" v-if="timerRest > 0">
            Por favor, aguarde {{ timerRest }} segundos, Estamos processando sua autenticação.
             Em seguida você pode concluir seu pedido.
          </p>
        </div>
        <!-- Message with countdown 3 min-->
        <div v-if="showMessageWithCountdownTwo" class="mt-4">
          <p class="text-color" v-if="timerRest > 0">
            Por favor, aguarde {{ timerRest }} segundos, estamos processamos sua
            autenticação. Em seguida você poderá concluir seu pedido.
          </p>
        </div>
        <div
          class="modal fade"
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true" >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Modal title
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>     
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/Modal/ModalAutentica.vue";
export default {
  name: "RemakeAutentica",
  components: {
    Modal,
  },
  data() {
    return {
      showModal: true,
      try: 0,
      timerRest: 0,
      timer: null,
      showCountdown: false,
      showMessageWithCountdownOne: false,
      showMessageWithCountdownTwo: false,
      showVerifyButtonTwo: true,
      showVerifyButtonOne: true,
      ok: false,
      showOkButton: true,
    };
  },
  computed: {
    ...mapGetters({
      getLinkComputed: "saleFlow/operators/claro/authenticate/getLink"
    })
  },
  methods: {
    ...mapActions({
      generateClaroAuthenticateLinkAction:
        "saleFlow/operators/claro/authenticate/generateLink"
    }),
    startVerification() {
      window.open(this.getLinkComputed, "_blank");
      this.showVerifyButtonTwo = false;
      this.showVerifyButtonOne = false;
      this.ok = true;
      this.showCountdown = false;
    },
    authenticationPerformed() {
      this.showCountdown = true;
      this.showMessageWithCountdownOne = true;
      this.startCountdownOne();
      this.ok = false;
      this.showOkButton = false;
    },
    completedAuthentication() {
      this.showCountdown = true;
      this.showMessageWithCountdownTwo = true;
      this.startCountdownTwo();
      this.ok = false;
      this.showOkButton = false;
    },
    startCountdownOne() {
      this.timerRest = 60;
      const interval = setInterval(() => {
        this.timerRest--;
        if (this.timerRest <= 0) {
          clearInterval(interval);
          this.showModal = false;
        }
      }, 1000);
    },
    startCountdownTwo() {
      this.timerRest = 180;
      const interval = setInterval(() => {
        this.timerRest--;
        if (this.timerRest <= 0) {
          clearInterval(interval);
          this.showModal = false;
        }
      }, 1000);
    },
    closeModal() {
      this.showModal = false;
    }
  }
};
</script>

<style scoped>
.text1 {
  text-align: -webkit-center;
}
.text-color {
  color: red;
  font-size: 16px;
}
.center-div {
  text-align: -webkit-center;
}
</style>

