<template>
    <transition name="modal-animation">
      <div v-show="modalActive" class="modal row">
        <transition name="modal-animation-inner">
          <div v-show="modalActive" class="modal-inner rounded">
            <!-- Modal Content -->
            <slot :close="close" />
          </div>
        </transition>
      </div>
    </transition>
  </template>
  <script>
  export default {
    name: "WarningModal",
    props: {
      close: {
        type: Function,
        default: () => {}
      },
      modalActive: {
        type: Boolean,
        default: true,
      }
    }
  };
  </script>
  <style lang="scss" scoped>
  .modal-animation-enter-active,
  .modal-animation-leave-active {
    transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
  }
  
  .modal-animation-enter-from,
  .modal-animation-leave-to {
    opacity: 0;
  }
  
  .modal-animation-inner-enter-active {
    transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
  }
  
  .modal-animation-inner-leave-active {
    transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
  }
  
  .modal-animation-inner-enter-from {
    opacity: 0;
    transform: scale(0.8);
  }
  
  .modal-animation-inner-leave-to {
    transform: scale(0.8);
  }
  
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 105%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(63, 62, 62, 0.7);
  }
  .modal-inner {
    position: relative;
    max-width: 640px;
    width: 70%;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background-color: #fff;
    padding: 40px -20px;
    top: -20%;
   
  } 
  button {
    padding: 20px 30px;
    border: none;
    font-size: 16px;
    background-color: crimson;
    color: #fff;
    cursor: pointer;
  }
  @media screen and (min-width: 768px) {
   
    .modal-inner {
    position: relative;
    max-width: 640px;
    width: 27%;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background-color: #fff;
    padding: 40px -20px;
    top: -20%;
   
  } 
    
  }
  </style>
  