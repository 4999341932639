<template>
  <div class="mt-3" v-if="getShowClaroFieldsComputed">
    <div
      class="container"
      v-for="cartItem in getCartItems"
      :key="cartItem.item.product.id">
      <b-card class="shadow border-1" style="background-color: #f3f3f3">
        <img
          src="@/assets/img/companies/McAfee/McAfeeCommon/iconBuyEmpty.svg"
          class="img-auto"
          alt="Responsive image"
        />
        <span>Pedido</span>
        <div class="col-6">
          <b-button
            @click="removeCart(cartItem.item.product)"
            type="button"
            class="rounded edit-button text-button p-1"
            style="color: blue">
            <img src="@/assets/icons/vector.svg" alt="" />
            <span class="ms-1">Remover</span>
          </b-button>
        </div>
        <b-card-title class="text-card-product-list mt-3">{{cartItem.item.product.name}}</b-card-title>
        <b-card-text class="large">
          <br/>
          <div class="price-mcafee">
            {{ cartItem.item.promotion?.description }}____
            {{ currencyBRFormat(cartItem.price) }}
          </div>
          <div v-if="checkHasAdditionalPointsByCartItem(cartItem)">
          <div
            v-for="cartItem in getCartAdditionalAmountValue"
            :key="cartItem.item.productResidentialPointAdditionalType?.id">
            <div class="price-mcafee">
              {{ cartItem.item.productResidentialPointAdditionalAmount }} pontos adicionais
              {{ cartItem.item.productResidentialPointAdditionalType?.description }} ____ {{ currencyBRFormat(cartItem.itemPoint) }}
            </div>
          </div>
          </div>
          <br />
        </b-card-text>
        <b-card-text class="large">Valor total </b-card-text>
        <b-card-text class="text-card-product-list">{{
          currencyBRFormat(getCartTotalPricePoints)
        }}/mês</b-card-text>
      </b-card>
    </div>
  </div>
  <div class="mt-3" v-if="hasAvailableProductTypeMcafeeComputed">
    <div
      class="container"
      v-for="cartItem in getCartItems"
      :key="cartItem.item.product.id">
      <b-card class="shadow border-1" style="background-color: #f3f3f3">
        <img
          src="@/assets/img/companies/McAfee/McAfeeCommon/iconBuyEmpty.svg"
          class="img-auto"
          alt="Responsive image"
        />
        <span>Pedido</span>
        <div class="col-6">
          <b-button
            @click="removeCart(cartItem.item.product)"
            type="button"
            class="rounded edit-button text-button p-1"
            style="color: blue">
            <img src="@/assets/icons/vector.svg" alt="" />
            <span class="ms-1">Remover</span>
          </b-button>
        </div>
        <b-card-title class="text-card-product-list mt-3">{{cartItem.item.product.name}}</b-card-title>
        <b-card-text class="large">
          <br />
        </b-card-text>
        <b-card-text class="small">{{ cartItem.item.product.description }}</b-card-text>
        <b-card-text class="large">Valor total</b-card-text>
        <b-card-text class="text-card-product-list" >{{ currencyBRFormat(getCartTotalPrice) }}/ano</b-card-text>
      </b-card>
    </div>
  </div>
</template>
<script>
import MoneyService from "@/services/numbers/money";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "ListProduct",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getCartCheckoutDetailsComputed: "saleFlow/cart/getCheckoutDetails",
      getShowClaroFieldsComputed: "address/getShowClaroFields",
      getCartDetailsComputed: "cart/getCartDetails",
      hasAvailableProductTypeClaroComputed:"saleFlow/product/hasAvailableProductTypeClaro",
      requireShowModalAuthenticateComputed:"saleFlow/operators/claro/authenticate/requireShowModalAuthenticate",
      hasAvailableProductTypeMcafeeComputed: "saleFlow/product/hasAvailableProductTypeMcafee",
      getCheckoutProductsItemsComputed: "saleFlow/cart/getCheckoutProductsItems",
    }),
    ...mapState({
      isShowingCart: (state) => state.cart.isShowingCart,
    }),
    getCartItems() {
      return this.getCartCheckoutDetailsComputed?.items || [];
    },
    getCartTotalPrice() {
      return this.getCartCheckoutDetailsComputed?.total || 0;
    },
    currencyBRFormat() {
      return (value) => MoneyService.currencyBRFormat(value || 0);
    },
        getCartAdditionalAmountValue() {
      return this.getCartDetailsComputed?.items || 0;
    },
      getCartTotalPricePoints(){
      return this.getCartDetailsComputed?.totalPrice || 0
    }    
  },
    methods: {
      ...mapActions({
        removeCartItemAction: 'cart/removeProductCart', 
        removeProductAction: 'saleFlow/cart/removeProduct', 
      }),
      removeCart(product) {
        this.removeCartItemAction(product)
        const checkoutProductId = this.getCheckoutProductsItemsComputed[0].id
         this.removeProductAction(checkoutProductId)
        return this.$swal( "Olá, seu carrinho está vazio. você pode voltar e escolher um produto. ", " ",this.$router.push({ path: '/clarotv' }) ); 
    },
      checkHasAdditionalPointsByCartItem(cartItem){
        return cartItem?.item?.checkoutProduct?.productResidentialPointAdditionalAmount >0
    }
  },
};
</script>
<style scoped>
.text-card-product-list {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #29333d;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.edit-button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 24px;
  gap: 10px;
  color: #165dba;
  position: absolute;
  left: 65.88%;
  right: 2%;
  top: 3%;
  bottom: -10;
  background: #ffffff;
  border: 2px solid #d4e0f7;
  border-radius: 8px;
}
.text-button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  color: #165dba;
  flex: none;
  order: 1;
  flex-grow: 0;
}
</style>