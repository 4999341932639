<template>
  <div class="mt-3">
    <div class="container">
      <b-card class="shadow border-1" style="background-color: #f3f3f3">
        <img
          src="@/assets/img/companies/McAfee/McAfeeCommon/Board.svg"
          class="img-auto mx-1"
          alt="Responsive image"
        />
        <span class="mx-1">Dados de Instalação</span>
          <button class="edit-button text-button p-0" style="color: blue" @click="returnInstallationData">
              <Icon name="edit" class="icon-center text-center" />
            <span class="ms-1">Editar</span>
          </button>
        <div class="col-12 row mt-3">
          <b-card-text class="text-card-data-client col-6"> Data 1: <br>
            <strong class="data-client">{{ formatDate(getSchedules[0]?.date) }}</strong>
          </b-card-text>
          <b-card-text class="text-card-data-client col-6">Data 2: <br>
            <strong class="data-client">{{ formatDate(getSchedules[1]?.date) }}</strong>
          </b-card-text>
           <b-card-text class="text-card-data-client col-12"> Período 1: <br>
            <strong class="data-client">{{ getProductInstallationSchedulePeriod[0]?.description }}</strong>
          </b-card-text>
          <b-card-text class="text-card-data-client col-12"> Período 2: <br>
            <strong class="data-client">{{ getProductInstallationSchedulePeriod[1]?.description}}</strong>
          </b-card-text>
          <b-card-text class="text-card-data-client col-6"> Contato para instalação:<br>
            <strong class="data-client">{{ getContacts[0]?.name }}</strong>
          </b-card-text>
          <b-card-text class="text-card-data-client col-6">Telefone:<br>
            <strong class="data-client">{{ formatPhone(getContacts[0]?.phone) }}</strong>
          </b-card-text>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import moment from "moment";
import Icon from "@/components/Icon/Icon.vue"
export default {
  name: "ReviewInstallationData",
  computed: {
    ...mapGetters({
      getSchedules: "saleFlow/schedule/getSchedules",
      getProductInstallationSchedulePeriod: "saleFlow/schedule/getProductInstallationSchedulePeriod",
      getContacts: "saleFlow/schedule/getContacts"
    })
  },
  components:{
    Icon
  },
  methods:{
 formatDate(date) {
    const momentDate = moment(date);
    if (momentDate.isValid()) {
      return momentDate.format('DD/MM/YYYY');
    } else {
      return date;
    }
  },
    formatPhone(phone) {
      const cleaned = phone.replace(/\D/g, '').replace(/^55/, '');
      if (cleaned.length === 11) {
        return `(${cleaned.substring(0, 2)}) ${cleaned.substring(2, 7)}-${cleaned.substring(7, 11)}`;
      }
      return phone;
    },
    returnInstallationData(){
      this.$router.push({ name: 'ProductStep' })
    }
  }
};
</script>
<style scoped>
.text-card-data-client {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.004em;
  color: #5c6670;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.data-client {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #29333d;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.edit-button {   
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 10px;
  color: #165DBA;
  position: absolute;
  left: 65.88%;
  right: 2%;
  top: 3%;
  bottom: -10;
  background: #FFFFFF;
  border: 2px solid #D4E0F7;
  border-radius: 8px;
}
.text-button {
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 13px;
line-height: 16px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.0125em;
color: #165DBA;
flex: none;
order: 1;
flex-grow: 0;
}
</style>