<template>
  <div class="row ">
    <div class="text-center card-car">
      <div class="elipse ">
        <img src="@/assets/img/companies/McAfee/McAfeeCommon/iconBuyEmpty.svg" alt="carrinho de compras" class="img-auto mt-1" style="width: 53px; height: 48px;">
      </div>
      <span class="text-car">Carrinho</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardCartComponent"
};
</script>
<style scoped>
.elipse {
background: #FFFFFF;
border-radius: 50px;
width: 53px;
height: 51px;
}
.text-car {
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 26px;
line-height: 30px;
display: flex;
padding-bottom: 15%;
padding-top: 15%;

/* Brand/Secondary */
color: #FFFFFF;

/* Inside auto layout */
flex: none;
order: 1;
}
.card-car {
display: flex;
align-items: center;
padding: 30px 32px;
gap: 16px;
width: 100%;
height: 64px;

/* Company/Primary +1 */
background: #1D3C85;

/* Inside auto layout */
order: 3;
flex-grow: 0;
}
</style>