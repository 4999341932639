<template>
    <Modal
      :close="closeModal"
      :modal-active="showModal"> 
      <template v-slot:default="{ close }">
        <div class="container text-center mt-3  col-12">
          <div class="container text-center mt-3  col-12">
            <Icon name="warning" 
                  class="warning-icon mt-4"
            />
          </div>
          <div class="row">
            <span class="container-fluid mt-1 text-center warning-text">
              
            </span>
          </div>
          <div class=" mt-2 text-center text" >
             <slot />
          </div>
          <div class="container mt-3">
            <span class="text-center d-flex justify-content-center warning-text-2"></span>
            <div class="d-flex justify-content-around mt-4 mb-3 row">
              <b-button
                  type="button"
                  class="btn border rounded button-close col-5"
                  @click="close" >
               Fechar
              </b-button> 
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </template> 
  <script>
  import Modal from "@/components/Modal/WarningModal"
  
  export default {
    name: "ModalWarning",
    components: {
      Modal
    },
    data () {
      return {
        showModal: true
      }
    },
    methods: {
        returnToCustomerStep(){
            this.$router.push({name: 'CustomerStep'})
        },
      closeModal () {
        this.showModal = false
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  .warning-text{
    color:crimson; 
    font-size: 18px;
  }
  .warning-text-2{
    color:crimson; 
    font-size: 14px;
  }
  .warning-icon{
    width: 45px;
  }
  .button-close{
    color: #FFF; 
    font-size: small;
    background-color: #1D3C85;
  }
  .button-accept {
    color: #1D3C85;
    background-color: #FFFF; 
    font-size: small;
  }
  .text{
    color: #141414;
    font-size: 16px;
  }
  </style>