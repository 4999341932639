<template>
  <CardCartComponent/>
  <CheckoutStep/>
  <div class="form-customer" v-if="hasAvailableProductTypeMcafeeComputed">
    <span class="text-form row">Para concluir o seu pedido, informe seus dados.</span>
    <div class="container-fluid row">
      <CustomerForm />
    </div>
  </div>

    <div class="form-customer"  v-if="getShowClaroFieldsComputed">
    <span class="text-form row">Por favor, confira seus dados.</span>
    <div class="container-fluid row">
      <CustomerNextStep/>
    </div>
  </div>
</template>
<script>
import CardCartComponent from "@/components/Cards/CardPernambucanas.vue";
import CheckoutStep from "@/components/Steps/PersonalStep.vue";
import CustomerNextStep from "@/components/Form/Form/CustomerNextStep.vue";
import CustomerForm from "@/components/Form/CustomerForm.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Customer",
  data() {
    return {
      customer: {
        cpf: '',
        name: '',
        email: '',
        phone1: '',
        motherName: 'Não Informado',
        birthdate: '1970-01-01'
      },
      address: {
        cep: '',
        street: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: ''
      },
    }
  },
  components: {
    CardCartComponent,
    CheckoutStep,
    CustomerNextStep,
    CustomerForm,
  },
  computed:{
    ...mapGetters({
      filledAddress: "address/getAddresses",
      getShowClaroFieldsComputed: "address/getShowClaroFields",
      hasAvailableProductTypeMcafeeComputed: 'saleFlow/product/hasAvailableProductTypeMcafee',
    })
  }
}
</script>
<style scoped>
.form-customer {
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10px;
  color: darkgray;
  font-size: 12px;
}
.form-adress {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  color: darkgray;
  font-size: 12px;
}
.text-form {
  font-size: 14px;
  color: rgb(88, 87, 87);
  font-weight: bold;
  align-items: center;
  justify-self: center;
  margin-left: 10px;
}
.button-next {
  margin-left: 10px;
  margin-right: 10px;
}
.checkbox-terms {
  margin-left: 5px;
  font-size: 11px;
  color: rgb(66, 65, 65);
}
.container-checkbox {
  margin-bottom: 100px;
}
</style>