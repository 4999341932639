<template>
  <div class="container-fluid mt-2">
    <div>
      <div class="row">
        <div class="col-12 col-sm-9">
          <span>Nome*</span>
          <input
          disabled
            type="text"
            label="Nome"
            class="form-control text-input"
            v-model="customer.name"  
          />
        </div>
        <div class="col-12 col-sm-9">
          <span>CPF*</span>
          <input
            disabled
            type="text"
            label="CPF"
            class="form-control text-input"
            v-model="customer.cpf" 
            v-mask="'###.###.###-##'"
          />
        </div>
      </div>
      <div class="row" v-if="showClaro">
        <div class="col-12 col-sm-9">
          <span>Data de nascimento</span>
          <input
            disabled
            type="text"
            class="form-control text-input"
            v-model="customer.birthdate"
            v-mask="'##/##/####'"            
          />
        </div>
      </div>
      <div class="row">
        <div class="form-adress container">
          <!------------Form-Address------------->
          <div class="row">
            <div class="col-12 mt-2">
              <div class="row">
                <div class="col-6 col-sm-9">
                  <span>CEP*</span>
                  <input
                  disabled
                    type="text"
                    label="CEP"
                    class="form-control text-input"
                    v-model="filledAddress.postalCode"
                  />  
                </div>
                <div class="col-12 col-sm-9" >
                  <span>Endereço*</span>
                  <input
                  disabled
                    type="text"
                    label="Endereço"
                    class="form-control text-input"
                    v-model="filledAddress.streetName"
                  />   
                </div>
                <div class="col-6 col-sm-9">
                  <span>Número*</span>
                  <input
                  disabled
                    type="text"
                    label="Número"
                    class="form-control text-input"
                    v-model="customerAddress.number"
                  />   
                </div>
                <div class="col-6 col-sm-9">
                  <span>Complemento*</span>
                  <input
                  disabled
                    type="text"
                    label="Complemento"
                    class="form-control text-input"
                    v-model="customerAddress.complement"
                  />  
                </div>          
                <div class="col-12 col-sm-9">
                  <span>Bairro*</span>
                  <input
                  disabled
                    type="text"
                    label="Bairro"
                    class="form-control text-input"
                    v-model="filledAddress.neighborhoodName"
                  /> 
                </div>
                <div class="col-12 col-sm-9">
                  <span>Cidade*</span>
                  <input
                  disabled
                    type="text"
                    label="Cidade"
                    class="form-control text-input"
                    v-model="filledAddress.cityName"                    
                  />                  
                </div>
                <div class="col-4 col-sm-4">
                  <span>UF*</span>
                  <input
                    class="form-control text-input"
                    v-model="filledAddress.stateName"
                    :options="stateOptions"
                    style="font-size: 11px;"
                    disabled
                  />  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid col-12 mt-5 container-checkbox text-center">
    <span class="checkbox-terms">Ao continuar, você concorda com os <a >termos de uso.</a></span>
    <br />
    <br />
    <div class="col-12">
      <Button
        tag="Continuar"
        pill
        variant="danger"
        type="submit"
        value="Submit"
        class="btn btn-primary text-center col-12 col-sm-4 col-md-4 col-lg-6 loading-button"
        @click="nextStep">
        <span v-if="!processing">Continuar</span>
        <span v-else>
          <div class="text-center">
            <strong class="text-sm">Aguarde o processamento ...</strong>
            <div
              class="spinner-border ml-auto"
              role="status"
              aria-hidden="true" style="width: 16px; height: 16px;">
            </div>
          </div>
        </span>
      </Button>
    </div>
  </div>
</template>
<script>
import suite from "@/services/validations/customerValidations.js";
import suiteA from "@/services/validations/adressValidations.js";
import { mapGetters, mapActions } from "vuex";
import Button from "@/components/Form/Button/Button.vue";
import moment from "moment";
export default {
  name: "CustomerNextStep",
  data() {
    return {
      hideBoth: false,
      showClaro: false,
      showMcafee: false,
      showingClaro: false,
      processingCep: false,
      processing: false,
      customer: {
        cpf: "",
        name: "",
        birthdate: "",
        email: "",
        phone1: "",
        motherName: "",
        id: ""
      },
      filledAddress: {
        postalCode: "",
        cep: "",
        streetName: "",
        number: "",
        complement: "",
        neighborhoodName: "",
        cityName: "",
        stateName: "",
        id: "",
      },
      formAble: false,
      customerAddress: {
        customerId: "",
        addressId: "",
        number: "",
        complement: "",
      },
      pointOfSale: {
        id: ""
      },
      tagOrigin: "Fluxo de vendas OnLine  TradeAppOne",
      saleOfServiceId: null,
      stateOptions: [
        {value:"AC" , text:"AC"},
        {value:"AL" , text:"AL"},
        {value:"AP" , text:"AP"},
        {value:"AM" , text:"AM"},
        {value:"BA" , text:"BA"},
        {value:"CE" , text:"CE"},
        {value:"DF" , text:"DF"},
        {value:"ES" , text:"ES"},
        {value:"GO" , text:"GO"},
        {value:"MA" , text:"MA"},
        {value:"MT" , text:"MT"},
        {value:"MS" , text:"MS"},
        {value:"MG" , text:"MG"},
        {value:"PA" , text:"PA"},
        {value:"PB" , text:"PB"},
        {value:"PR" , text:"PR"},
        {value:"PE" , text:"PE"},
        {value:"PI" , text:"PI"},
        {value:"RJ" , text:"RJ"},
        {value:"RN" , text:"RN"},
        {value:"RS" , text:"RS"},
        {value:"RO" , text:"RO"},
        {value:"RR" , text:"RR"},
        {value:"SC" , text:"SC"},
        {value:"SP" , text:"SP"},
        {value:"SE" , text:"SE"},
        {value:"TO" , text:"TO"},
      ],
      zipcodes: [],
      res: suite.get(),
      resA: suiteA.get()
    };
  },
  components: {
    Button
  },
  computed: {
    ...mapGetters({
      customerComputed: "customer/getCustomer",
            customerAddressComputed: "customer/getCustomerAddress",
      hasAvailableProductTypeClaroComputed:"saleFlow/product/hasAvailableProductTypeClaro",
      requireShowModalAuthenticateComputed:"saleFlow/operators/claro/authenticate/requireShowModalAuthenticate",
      isValidClaroAuthenticateLinkComputed: "saleFlow/operators/claro/authenticate/isValidLink",
      getShowClaroFieldsComputed: "address/getShowClaroFields",
      filledAddressComputed: "address/getAddresses",
      hasCheckoutProductThatRequireClaroAuthenticate: 'saleFlow/cart/hasCheckoutProductThatRequireClaroAuthenticate',  
    }),
    showModal() {
      return this.hasCheckoutProductThatRequireClaroAuthenticate;
    },
  }, 
  methods: {
    ...mapActions({
      customerAssociateAddressAction: "customer/associateAddress",
      customerSaveAction: "customer/save",
      addCustomerAction: "saleFlow/addCustomer",
      getProductsCatalogAction: "saleFlow/getProductsCatalog",
      checkoutPaymentOptions: "saleFlow/checkoutPaymentOptions",
      validateIfAllCartProductsCanBeSoldAction:"saleFlow/validateIfAllCartProductsCanBeSold",
      processCartToCheckoutAction: "saleFlow/processCartToCheckout",
      generateClaroAuthenticateLinkAction:"saleFlow/operators/claro/authenticate/generateLink",
       filledAddressAction: "address/getAddresses",
    }),
    enableButton() {
      this.processing = false;
    },
    goNextStep(){
      this.$router.push({ name: 'PaymentStep' });
    },
    async nextStep() {
      this.processing = true;
      try {
        await this.getProductsCatalogAction();
        const validation = await this.validateIfAllCartProductsCanBeSoldAction();
        if (validation?.validated === true) {
            await this.processCartToCheckoutAction();
            await (() => this.checkoutPaymentOptionsAction)();
            await this.$router.push({ name: "PaymentStep" });
        } else {
            throw new Error("Cart has product that can't be sold");
        }
      } catch (error) {
        this.enableButton();
        console.error("Error occurred:", error);
        this.$swal(
            "Ocorreu um erro de processamento, por favor, tente novamente.",
            " ",
            "error"
        );
      }
      this.processing = false;
    }, 
    formatDate(date) {
    const momentDate = moment(date);
    if (momentDate.isValid()) {
      return momentDate.format('DD/MM/YYYY');
    } else {
      return date;
      }
    },
    getDataCustomer() { 
      if(this.getShowClaroFieldsComputed !== false  ){
        this.showClaro = true;
        const customer = this.$store.getters["customer/getCustomer"];
       const customerAddress = this.$store.getters["customer/getCustomerAddress"];
        const filledAddress = this.$store.getters["address/getCurrentAddress"];        
          if (customer !== null &&  filledAddress!== null) {
            this.customer.cpf = customer.cpf;
            this.customer.name = customer.name;
            this.customer.email = customer.email;
            this.customer.phone1 = customer.phone1;
            this.customer.birthdate = this.formatDate(customer.birthdate);
            this.customer.motherName = customer.motherName;
            this.customer.id = customer.id;
            this.filledAddress.postalCode = filledAddress?.postalCode;
            this.filledAddress.streetName = filledAddress?.streetName;
            this.customerAddress.number = customerAddress?.number;
            this.customerAddress.complement = customerAddress?.complement;
            this.filledAddress.neighborhoodName = filledAddress?.neighborhoodName;
            this.filledAddress.cityName = filledAddress?.cityName;
            this.filledAddress.stateName = filledAddress?.stateName;
        }
        }else{
        return
      }     
    },
  },
  created() {
    this.getDataCustomer(); 
  }
};
</script>
<style lang="scss" scoped>
.is-invalid {
  border-color: red;
}
.form-adress {
  margin-top: 20px;
  margin-left: 0px;
  margin-right: 0px;
  color: darkgray;
  font-size: 12px;
}
.text-form {
  font-size: 14px;
  color: rgb(88, 87, 87);
  align-items: center;
  justify-self: center;
  font-weight: bold;
}
.checkbox-terms {
  margin-left: 5px;
  font-size: 11px;
  color: rgb(66, 65, 65);
}
.container-checkbox {
  margin-bottom: 100px;
}
.text-input {
  font-size: 11px;
  color: black;
}
.text-cep {
  font-size: 10px;
}
.loading-button i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: spinner 1s infinite linear;
}
@keyframes spinner {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
  </style>